<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="8"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h5 class="mb-50 mb-sm-0">
            {{ $t('revenue report') }}
          </h5>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span>{{ $t('income') }}</span>
            </div>
            <div class="d-flex align-items-center ml-75">
              <span class="bullet bullet-danger svg-font-small-3 mr-50 cursor-pointer" />
              <span>{{ $t('expenses') }}</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          type="bar"
          height="250"
          :options="revenueReport.chartOptions"
          :series="reports.chart.series"
        />
      </b-col>

      <b-col
        md="4"
        class="budget-wrapper mt-2"
      >
        <b-dropdown
          :text="selectedYear"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="year in years"
            :key="year"
            @click="selectedYear = year; yearlyReports()"
          >
            {{ year }}
          </b-dropdown-item>
        </b-dropdown>

        <h2 class="mb-25">
          ${{ `${numberWithCommas(totalRevenue - totalExpense)}` }}
        </h2>
        <vue-apex-charts
          id="budget-chart"
          type="line"
          height="80"
          :options="budgetChart.options"
          :series="reports.budget.series"
        />
        <div class="d-flex">
          <span class="font-weight-bolder mr-25">{{ `${$t('revenue')}: ` }} </span>
          <span>${{ numberWithCommas(totalRevenue) }}</span>
        </div>
        <div class="d-flex">
          <span class="font-weight-bolder mr-25">{{ `${$t('expenses')}: ` }} </span>
          <span>${{ numberWithCommas(totalExpense) }}</span>
        </div>
        <div class="d-flex">
          <span class="font-weight-bolder mr-25">{{ `${$t('loaned out')}: ` }} </span>
          <span>${{ numberWithCommas(totalLoan) }}</span>
        </div>
        <div class="d-flex">
          <span class="font-weight-bolder mr-25">{{ `${$t('principal returned')}: ` }}</span>
          <span>${{ numberWithCommas(totalPrincipal) }}</span>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import ReportsModule from '@/store/module/reportsModule'
import store from '@/store'
// import { mapGetters } from 'vuex'

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    sid: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      totalRevenue: 0,
      totalExpense: 0,
      totalLoan: 0,
      totalPrincipal: 0,
      reports: {
        chart: {
          series: [],
        },
        budget: {
          series: [],
        },
      },
      years: [],
      selectedYear: this.$moment().format('YYYY'),
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: [`${this.$t('Jan')}`, `${this.$t('Feb')}`, `${this.$t('Mar')}`, `${this.$t('Apr')}`, `${this.$t('May')}`, `${this.$t('Jun')}`, `${this.$t('Jul')}`, `${this.$t('Aug')}`, `${this.$t('Sep')}`, `${this.$t('Oct')}`, `${this.$t('Nov')}`, `${this.$t('Dec')}`],
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.danger],
          plotOptions: {
            bar: {
              columnWidth: '20%',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
              formatter(val) {
                if (val) {
                  const number = +val
                  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                return 0
              },
            },
          },
          tooltip: {
            y: {
              title: { formatter: seriesName => this.$t(seriesName) },
            },
            z: {
              formatter(val) {
                const nV = val.map(i => {
                  if (i) {
                    const number = +i
                    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  return 0
                })
                return `${nV[0]}, ${nV[1]}`
              },
              title: `${this.$t('interest')}, ${this.$t('other')}: `,
            },
            marker: true,
          },
        },
      },
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: false,
          },
        },
      },
    }
  },
  mounted() {
    for (let y = 2021; y <= this.selectedYear; y += 1) {
      this.years.push(y.toString())
    }
    this.$store.registerModule('reports', ReportsModule)
    this.yearlyReports()
  },
  beforeDestroy() {
    this.$store.unregisterModule('reports')
  },
  methods: {
    yearlyReports() {
      store.dispatch('yearlyReports', { year: this.selectedYear, sid: this.sid }).then(result => {
        this.totalRevenue = result.totalRevenue
        this.totalExpense = result.totalExpense
        this.totalLoan = result.totalLoan
        this.totalPrincipal = result.totalPrincipal
        this.reports.chart.series = [
          {
            name: 'income',
            data: result.revenue,
          },
          {
            name: 'expense',
            data: result.expense.map(i => ({ ...i, y: `-${i.y}` })),
          },
        ]
        this.reports.budget.series = [
          {
            data: result.revenue,
          },
          {
            data: result.expense,
          },
        ]
      })
    },
  },
}
</script>
