/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import { reportsCollection } from '@/firebase'
import { add } from 'mathjs'
import Vue from 'vue'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  yearlyReports: async ({ rootState }, params) => new Promise((resolve, reject) => {
    const { year } = params
    let { sid } = params
    sid ||= rootState.users.currentStore.sid
    sid ||= rootState.users.user.sid

    const currentDate = new Date(year)
    const theFirst = new Date(currentDate.getFullYear(), 0, 1)
    const theLast = new Date(currentDate.getFullYear(), 11, 31)
    reportsCollection
      .where('sid', '==', sid)
      .where('createdAt', '>=', theFirst)
      .where('createdAt', '<=', theLast)
      .get()
      .then(store => {
        const revenue = []
        for (let month = 1; month <= 12; month += 1) {
          revenue.push({
            x: `${month}`,
            y: 0,
            z: [0, 0],
          })
        }
        const expense = [...revenue]
        let totalRevenue = 0
        let totalExpense = 0
        let totalLoan = 0
        let totalPrincipal = 0
        if (!store.empty) {
          store.forEach(report => {
            const r = report.data()
            const date = r.createdAt.toDate()
            let index = Vue.moment(date).format('M')
            index -= 1
            revenue[index] = {
              x: `${index + 1}`,
              y: +r.revenue,
              z: [r.payment, +add(r.penalty, r.allowance)],
            }
            expense[index] = {
              x: `${index + 1}`,
              y: +add(r.expense, r.loan),
              z: [-r.loan, -r.expense],
            }
            totalRevenue = +add(totalRevenue, r.revenue)
            totalExpense = +add(totalExpense, r.expense)
            totalLoan = +add(totalLoan, r.loan)
            totalPrincipal = +add(totalPrincipal, r.principal)
          })
          resolve({
            revenue, expense, totalRevenue, totalExpense, totalLoan, totalPrincipal,
          })
        }
        resolve({ revenue, expense })
      })
      .catch(error => {
        reject(error)
      })
  }),
}

export default {
  state,
  mutations,
  getters,
  actions,
}
