<template>
  <div>
    <b-card
      no-body
      class="pt-2"
    >
      <b-row no-gutters>
        <!--
          Daterange-picker
        -->
        <b-col
          class="pl-1 mb-2 pr-1 col-md-2 col-12"
        >
          <b-form-datepicker
            v-model="transactionFilter.date"
            @input="handleFetchTransactions"
          />
        </b-col>

        <!--
          Dropdown of feature
        -->
        <b-col
          class="px-1 mb-2 col-md-2 col-12"
        >
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="full-width-dropdown w-100"
            :text="$t(filterTransactions.find(d => d.key === transactionFilter.type).name)"
          >
            <b-dropdown-item
              v-for="item in filterTransactions"
              :key="item.key"
              @click="handleFeatureSelection(item.key)"
            >
              {{ $t(item.name) }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

        <b-col
          class="px-1 mb-2 col-md-2 col-12"
        >
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="full-width-dropdown w-100"
            :text="selectedUserName === 'all' ? $t('all') : selectedUserName"
          >
            <b-dropdown-item
              v-for="(userData, uid) in storeUsers"
              :key="uid"
              @click="handleUserSelection(uid, userData.displayName)"
            >
              {{ userData.displayName }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

        <b-col
          class="col-md-6 col-12 px-1 mb-2"
        >
          <b-row>
            <b-col class="ml-auto col-md-8 col-12">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  :placeholder="$t('search') + '...'"
                />
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-row class="match-height">
      <!--
        Revenue Line chart
      -->
      <b-col sm="8">
        <transactions-revenue-report :sid="user.sid" />
      </b-col>

      <b-col sm="4">
        <b-card
          no-body
          class="p-2"
        >
          <vue-apex-charts
            height="120"
            :options="incomePieChartOptions"
            :series="ipaCounts"
          />
        </b-card>
        <b-row>
          <b-col sm="6">
            <money-view
              :value="totalExpenses"
              title="expenses"
              variant="danger"
              icon="DollarSignIcon"
            />
          </b-col>
          <b-col sm="6">
            <money-view
              :value="totalRevenue"
              title="income"
              variant="primary"
              icon="TrendingUpIcon"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-card
      no-body
    >
      <b-row class="my-2 mx-1">
        <b-col
          cols="5"
        >
          <h5>{{ $t('transactions') }}</h5>
        </b-col>
        <b-col
          cols="7"
          align="right"
          class="p-0"
        >
          <b-row>
            <b-col class="pr-0">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                class="text-nowrap"
                size="md"
                @click="handleDownloadCSV"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class=""
                  size="16"
                />
                <span class="align-middle"> {{ $t('download') }}</span>
              </b-button>
            </b-col>
            <b-col
              cols="1"
              class="mr-3 mr-md-0"
            >
              <b-button
                v-if="$can('create', 'transactions')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.add-Expenses-modal
                variant="primary"
                class="btn-icon"
              >
                <feather-icon icon="ShoppingBagIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-table
        id="transactions-table"
        responsive
        striped
        hover
        :items="transactions"
        :fields="transactionTableFields"
        :per-page="perPage"
        :current-page="currentPage"
        table-class="custom-table"
      >
        <template #cell(createdAt)="data">
          {{ data.item.createdAt | moment('YYYY/MM/DD HH:mm') }}
        </template>

        <template #cell(status)="data">
          {{ data.value.charAt(0).toUpperCase() + data.value.slice(1) }}
        </template>

        <template #cell(amount)="data">
          ${{ data.item.paid }}{{ data.item.paymentReceived }}
        </template>

        <template #cell(type)="data">
          {{ $t(data.value) }}
        </template>

        <template #cell(groups)="data">
          {{ data }}
        </template>

        <template #cell(customer)="data">
          <b-card-text class="m-0">
            {{ data.value.accountId }}
          </b-card-text>
          <h6 class="font-weight-bolder mb-0 text-primary">
            {{ data.value.name }}
          </h6>
        </template>
        <template #cell(user)="data">
          <b-avatar
            :src="data.value.photoURL"
          />
        </template>

        <template #cell(action)="data">
          <b-dropdown
            v-if="$can('delete', 'transactions')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            toggle-class="px-0 py-50 bg-transparent"
            variant="flat-dark"
            no-caret
            right
          >
            <template
              v-slot:button-content
            >
              <feather-icon
                :id="`box-pop-menu-${data.item.id}`"
                icon="MoreVerticalIcon"
                size="18"
                class="cursor-pointer"
              />
            </template>
            <b-dropdown-item
              @click="handleDeleteTransaction(data.item)"
            >
              {{ $t('delete') }}
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template #head()="data">
          <div style="min-width: 30px;">
            {{ $t(data.label) }}
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <b-card-text class="font-small-3 mb-0 ml-1">
            {{ totalRows === 0 ? `Showing 0 - ${totalRows} of ${totalRows} entries` :
              perPage*(currentPage-1)+perPage > totalRows ?
                `Showing ${perPage*(currentPage-1)+1} - ${totalRows} of ${totalRows} entries` :
                `Showing ${perPage*(currentPage-1)+1} - ${perPage*(currentPage-1)+perPage} of ${totalRows} entries`
            }}
          </b-card-text>
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="sm"
            class="mt-0 mb-1 mr-1"
          />
        </b-col>
      </b-row>
    </b-card>

    <!--
      Modal to add Expenses
    -->
    <b-modal
      id="add-Expenses-modal"
      :title="$t('expense')"
      ok-title="Add"
      :hide-header-close="true"
      cancel-title="Cancel"
      cancel-variant="outline-primary"
      footer-class="justify-content-end flex-row-reverse"
      centered
      @ok="handleAddExpense"
    >
      <b-form-group
        :label="$t('description')"
        label-for="Expenses-note"
      >
        <b-form-input
          id="Expenses-note"
          v-model="form.note"
          :placeholder="$t('description')"
        />
      </b-form-group>
      <b-form-group
        :label="$t('date')"
        label-for="Expenses-date"
      >
        <b-form-datepicker
          id="Expenses-date"
          v-model="form.date"
        />
      </b-form-group>
      <b-form-group
        :label="$t('time')"
        label-for="add-time"
      >
        <b-form-timepicker
          id="add-time"
          v-model="form.time"
          locale="en"
        />
      </b-form-group>
      <b-form-group
        :label="$t('Amount')"
        label-for="Expenses-amount"
      >
        <b-input-group
          id="Expenses-amount"
          prepend="$"
          append=".00"
          class="input-group-merge"
        >
          <b-form-input
            v-model="form.paid"
            :placeholder="$t('Amount')"
          />
        </b-input-group>
      </b-form-group>
    </b-modal>

  </div>
</template>
<script>
import {
  BCard, /* BCardTitle, BCardBody,  */BCardText, BFormDatepicker, BFormTimepicker, BDropdown, BDropdownItem, BFormInput, BButton, BRow, BCol, BTable, BPagination, BAvatar, BModal, VBModal, BInputGroup, BFormGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { mapGetters, mapActions } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { add, subtract } from 'mathjs'
// import downloadCsv from 'vue-json-csv'
import MoneyView from './components/MoneyView.vue'
import TransactionsRevenueReport from './components/TransactionsRevenueReport.vue'

export default {
  components: {
    BCard,
    BInputGroupPrepend,
    /* BCardTitle,
    BCardBody, */
    BCardText,
    BFormDatepicker,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BTable,
    BPagination,
    BAvatar,
    BModal,
    BInputGroup,
    BFormGroup,
    BFormTimepicker,
    VueApexCharts,
    MoneyView,
    TransactionsRevenueReport,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      downloadOptions: {
        columns: [
          'createdAt',
          'type',
          'note',
          'paymentReceived',
          'paid',
          'allowance',
          'userName',
          'customerName',
        ],
      },
      downloadLabels: {
        createdAt: 'createdAt',
        type: 'type',
        note: 'note',
        paymentReceived: 'paymentReceived',
        paid: 'paid',
        allowance: 'allowance',
        userName: 'userName',
        customerName: 'customerName',
      },
      transactionFilter: {
        date: this.$moment().format('YYYY-MM-DD'),
        type: 'all',
        uid: 'all',
      },
      form: {
        date: this.$moment().format('YYYY-MM-DD'),
        time: '12:00',
        note: null,
        paid: null,
      },
      filterTransactions: [
        {
          key: 'all',
          name: 'All',
        },
        {
          key: 'revenue',
          name: 'Revenue',
        },
        {
          key: 'expense',
          name: 'Expense',
        },
      ],
      incomeValues: [
        {
          name: 'interest',
          value: 66500,
        },
        {
          name: 'penalty',
          value: 9500,
        },
        {
          name: 'allowance',
          value: 5000,
        },
      ],
      transactionTableFields: [
        {
          key: 'createdAt',
          label: 'Date',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'type',
          label: 'type',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'note',
          label: 'Description',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true,
          class: 'text-right',
        },
        {
          key: 'penalty',
          label: 'penalty',
          sortable: true,
          class: 'text-right',
        },
        {
          key: 'allowance',
          label: 'allowance',
          sortable: true,
          class: 'text-right',
        },
        {
          key: 'group',
          label: 'Groups',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'customerName',
          label: 'Customers',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'userName',
          label: 'User',
          class: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          class: 'text-center',
        },
      ],
      perPage: 25,
      currentPage: 1,
      feature: 'all',
      selectedUserName: 'all',
      colorSet: { ...$themeColors },
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      store: 'getCurrentStore',
      storeUsers: 'getStoreUsers',
      transactions: 'getTransactions',
      getTransactionsByType: 'getTransactionsByType',
    }),
    totalRows: {
      get() {
        return this.transactions.length
      },
      set() {
        return 0
      },
    },
    ipaCounts() {
      let interests = 0
      let penalties = 0
      let allowances = 0

      const trans = this.transactions
      trans.forEach(tran => {
        if (tran.type === 'penalty') {
          penalties = +add(penalties, tran.paymentReceived)
        } else if (tran.type === 'interest') {
          interests = +add(interests, tran.paymentReceived)
        }
        if (tran.allowance) allowances = +add(allowances, tran.allowance)
      })

      return [
        interests,
        penalties,
        allowances,
      ]
    },
    totalExpenses() {
      return this.transactions.reduce((result, trans) => {
        // eslint-disable-next-line no-param-reassign
        if (trans.transactionType === 'expenses') result = +add(result, trans.paid)
        return result
      }, 0)
    },
    totalInterest() {
      return this.transactions.reduce((result, trans) => {
        // eslint-disable-next-line no-param-reassign
        if (trans.type === 'interest') result = +add(result, trans.paymentReceived)
        return result
      }, 0)
    },
    totalAllowance() {
      return this.transactions.reduce((result, trans) => {
        if (trans.transactionType === 'revenue') {
          // eslint-disable-next-line no-param-reassign
          if (trans.allowance) result = +add(result, trans.allowance)
        }
        return result
      }, 0)
    },
    totalRevenue() {
      return this.transactions.reduce((result, trans) => {
        if (trans.transactionType === 'revenue') {
          // eslint-disable-next-line no-param-reassign
          if (trans.paymentReceived) result = +add(result, trans.paymentReceived)
          // eslint-disable-next-line no-param-reassign
          if (trans.allowance) result = +add(result, trans.allowance)
        }
        return result
      }, 0)
    },
    totalProfit() {
      return +subtract(this.totalRevenue, this.totalExpenses)
    },
    incomePieChartOptions() {
      return {
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'left',
          markers: {
            width: 12,
            strokeWidth: '2px',
            strokeColor: undefined,
            fillColors: this.incomeValues.map(() => 'transparent'),
            radius: 12,
          },
          // formatter: seriesName => this.$i18n.messages[this.$i18n.locale][seriesName.charAt(0).toUpperCase() + seriesName.slice(1)],
        },
        comparedResult: [2, -3, 8],
        labels: this.incomeValues.map(d => this.$i18n.messages[this.$i18n.locale][d.name.charAt(0).toUpperCase() + d.name.slice(1)]),
        stroke: { width: 0 },
        colors: [this.colorSet.primary, this.colorSet.danger, this.colorSet.info],
        grid: {
          padding: {
            right: 0,
            bottom: 0,
            top: 0,
            left: 0,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                  fontSize: '11px',
                  color: '#D0D2D6',
                  marginBottom: '2px',
                },
                value: {
                  offsetY: -15,
                  fontSize: '14px',
                  color: '#D0D2D6',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return parseInt(val)
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: this.$t('income'),
                  fontSize: '11px',
                  color: '#D0D2D6',
                  formatter: () => (
                    this.ipaCounts.reduce((a, b) => a + b, 0)
                  ),
                },
              },
            },
          },
        },
      }
    },
  },
  watch: {
    transactionFilter: {
      handler(newValue) {
        this.fetchTransactions({
          sid: this.user.sid,
          date: newValue.date,
          type: newValue.type,
          uid: newValue.uid,
        })
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchTransactions({
      sid: this.user.sid, date: this.transactionFilter.date, type: this.transactionFilter.type, uid: this.transactionFilter.uid,
    }).then(() => {
      this.$set(this, 'totalRows', this.transactions.length)
    })
    this.fetchStoreUsers()
  },
  methods: {
    ...mapActions(['fetchTransactions', 'fetchStoreUsers']),
    handleFeatureSelection(key) {
      this.transactionFilter.type = key
    },
    handleUserSelection(uid, name) {
      this.transactionFilter.uid = uid
      this.$set(this, 'selectedUserName', name)
    },
    handleAddTransaction() {},
    handleAddExpense() {
      const dateTime = this.$moment(`${this.form.date} ${this.form.time}`, 'YYYY-MM-DD HH:mm').format()
      store.dispatch('createExpense', { ...this.user, ...this.form, dateTime })
    },
    async handleDeleteTransaction(data) {
      let customer = {}
      if (data.type !== 'expense') customer = await store.dispatch('fetchCustomer', { cid: data.cid })
      if (data.type === 'expense' || !data.loanDateTime || data.loanDateTime === '' || data.loanDateTime.isEqual(customer.loan.loanDateTime)) {
        store.dispatch('deleteTransaction', { ...data, customer })
      }
    },
    handleFetchTransactions(date) {
      this.fetchTransactions({
        sid: this.user.sid, date, type: this.transactionFilter.type, uid: this.transactionFilter.uid,
      })
    },
    handleDownloadCSV() {
      // Define headers based on visible table fields
      const headers = this.transactionTableFields
        .map(field => this.$t(field.label))
        .join(',')

      // Convert transactions to CSV rows
      const rows = this.transactions.map(transaction => [
        this.$moment(transaction.createdAt).format('YYYY/MM/DD HH:mm'),
        this.$t(transaction.type),
        transaction.note,
        transaction.paid || transaction.paymentReceived,
        transaction.penalty || '',
        transaction.allowance || '',
        transaction.group || '',
          transaction.customer?.name || '',
          transaction.user?.displayName || '',
      ].map(value => `"${value}"`).join(','))

      // Combine headers and rows
      const csvContent = [headers, ...rows].join('\n')

      // Create and trigger download
      const element = document.createElement('a')
      element.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`
      element.download = `transactions-${this.$moment().format('YYYY-MM-DD')}.csv`
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
#revenue-report-chart {
  .apexcharts-canvas {
    line[stroke='transparent'] {
      display: block;
    }
  }
}
.card-revenue-budget {
  .revenue-report-wrapper {
    #revenue-report-chart {
      .apexcharts-series {
        &[seriesName='income'] {
          transform: scaleY(0.965);
        }
        &[seriesName='expense'] {
          transform: scaleY(1.035);
        }
      }
    }
  }
}
</style>
